/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Fragment, useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import BodyClassName from "react-body-classname"

import Header from "../components/header"
import IEMessage from "../components/ieMessage"
import LogoGrey from "../images/logo_grey.png"

// import Hls from "hls.js";

import Play from "../images/icon_play.png";
import Pause from "../images/icon_pause.png";

import "../style/reset.scss"
import "../style/style.scss"

import useInterval from "../useInterval"

const MainLayout = ({
  onlineStation,
  getOnlineStation,
  onlineStationError,
  onlineStationLoading,
  children,
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [ playing, setPlaying ] = useState(false)
  const videoRef = useRef();
  // const [ amplitude, setAmplitude ] = useState(null);

  // useEffect(() => {
  //   play();
  // }, [videoRef]);

  const play = () => {
    if(!videoRef) {
      return;
    }
    const video = videoRef.current;
    try {
      if (video.paused) {
        var videoSrc = 'https://stream.streamhits.nl/stream/1/';
        video.src = videoSrc;
        video.addEventListener('loadedmetadata', function() {
          video.play();
          setPlaying(true);
        });
      } else {
        video.pause();
        setPlaying(false);
      }
    } catch (e) {
      console.error(e);
      alert('Kon de muziek niet starten, refresh de browser en probeer het nog eens.');
    }
    
  }

  const [refetchInterval, setRefetchInterval] = useState(5 * 60 * 1000)
  useInterval(() => {
    getOnlineStation(false, true)
  }, refetchInterval);

  return (
    <BodyClassName className={`web ${menuOpen ? "menu_open" : "menu_closed"}`}>
      <Fragment>
        <Header
          toggleMenu={() => setMenuOpen(!menuOpen)}
          siteTitle="Hitstream.fm"
        />

        <IEMessage />

        <main>{children}</main>

        <footer>
          <div className="footer_wrapper">
            <div className="container single-song-container">

              <img src={LogoGrey} width="130" alt="Hitstream.fm logo" />

              <video style={{ position: 'fixed', left: '-100vw' }} id="video" playsInline controls={true} ref={videoRef} />

              <a
                className={playing ? "play" : "pause"}
                onClick={play}
              />
    
              {!onlineStationError && !onlineStationLoading && onlineStation && (
                <p>
                  U luistert naar de hits van <span>{onlineStation.name}</span>
                </p>
              )}
            </div>
          </div>
        </footer>
      </Fragment>
    </BodyClassName>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
