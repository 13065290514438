import { navigate, Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import logo from '../images/logo.png';

const Header = ({ toggleMenu }) => (
	<header>
		<div className="container">
			<Link className="logo" to="/">
				<img src={logo} width="260" alt="Hitstream.fm logo" />
			</Link>
			<nav>
				<ul>
					<li>
						<a onClick={() => { toggleMenu(); navigate("/") }}>Home</a>
						{/* <Link to="/">Home</Link> */}
					</li>
					<li>
						<a onClick={() => { toggleMenu(); navigate("/nieuws") }}>Nieuws</a>
						{/* <Link to="/nieuws">Nieuws</Link> */}
					</li>
					<li>
						<a onClick={() => { toggleMenu(); navigate("/evenementen") }}>Evenementen</a>
						{/* <Link to="/evenementen">Evenementen</Link> */}
					</li>
					<li>
						<a onClick={() => { toggleMenu(); navigate("/stations") }}>Stations</a>
						{/* <Link to="/stations">Stations</Link> */}
					</li>
				</ul>
			</nav>
			<ul className="social">
				<li className="fb">
					<a target="_blank" href="https://www.facebook.com/hitstreamfm/" />
				</li>
				<li className="tw">
					<a target="_blank" href="https://twitter.com/Hitstream_Fm" />
				</li>
				<li className="ig">
					<a target="_blank" href="https://www.instagram.com/hitstream.fm/" />
				</li>
				<li className="yt">
					<a target="_blank" href="https://www.youtube.com/c/HitstreamOpnames" />
				</li>
				<li className="burn">
					<Link to="/openhaard"/>
				</li>
			</ul>
			<div id="menu-toggle" onClick={toggleMenu}>
				<span className="menu-global menu-top" />
				<span className="menu-global menu-middle" />
				<span className="menu-global menu-bottom" />
			</div>
		</div>
	</header>
	// <Link
	//   to="/"
	//   style={{
	//     color: `white`,
	//     textDecoration: `none`,
	//   }}
	// >
	//   {siteTitle}
	// </Link>
);

Header.propTypes = {
	siteTitle: PropTypes.string
};

Header.defaultProps = {
	siteTitle: ``
};

export default Header;
